/* Style for the custom scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Set width for vertical scrollbar */
  height: 5px; /* Set height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #94cb96; /* Set the track color */
  /* border-radius: 2px; */
}

::-webkit-scrollbar-thumb {
  background-color: #45a049; /* Green color for the thumb 45a049*/
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #94cb96; /* Darker green when hovered */
}
