.user-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .user-profile-name::after {
    content: "\25BC";
    margin-left: 5px;
  }
  
  .user-profile-name {
    margin-right: 10px;
  }
  
  .user-profile-dropdown {
    position: absolute;
    top: 180px;
    right: 1000;
    width: 180px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .user-profile-image {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .user-profile-image img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .user-profile-options {
    padding: 10px;
  }
  
  .user-profile-options ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .user-profile-options li {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .user-profile-options li:hover {
    background-color: #309255;
    color:white;
  }
  
  .user-profile-settings {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 10px;
  }
  
  .user-profile-settings i {
    font-size: 20px;
  }
  