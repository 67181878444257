  
  .round-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }

  .section{
    margin-bottom: '50px';
    background-color:'#ccdbe9';
    margin-top:'10px'
  }

  
  .card {
    margin: 20px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .card-text {
    margin-top: 10px;
  }
  
 

  @media screen and (max-width:786px) {
      .first-section{
         flex-direction: column;
         justify-content: center;
         align-items: center;
      }   
  }