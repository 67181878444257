.cart-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;
    cursor: pointer;
  }
  
  .cart-icon i {
    margin-right: 50px;
  }
  
  .cart-badge {
    position: absolute;
    top: -22px;
    right: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f00;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
  }
  