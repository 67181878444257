

.cart-page {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  
}



.inner-container {
  width: 80%;
  max-width: 1200px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 200px;
  margin-bottom: 50px;
  border-radius:10px;
}

.inner-container:hover {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
  transform: scale(1.0);
}

.left-section {
  width: 60%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
}

.charges{
  display: flex;
  justify-content: space-between;
}

input{
  width: 100%;
    height: 35px;
    padding: 0 20px;
    border: 1px solid rgba(48, 146, 85, 0.2);
    border-radius: 10px;
    background-color: #fff;
    color: #212832;
    font-size: 15px;
    font-weight: 500;
    outline: none;
}

.btn-step{
  margin-top: 15px;
  border-radius:10px;
  /* background-color: #23203b; */
  color: #fff;
  border: none;
  font-weight: 500;
  font-family: '"Helvetica Neue", Helvetica, Arial, sans-serif';
  cursor: pointer;
  width: 250px;
  transition: transform 0.2s ease;
  
}

 
.btn-step-1:hover {
  transform: translateY(-3px);
}

.btn-step:hover{
  transform: translateY(-3px);
}

.btn-step-1{
  margin-top: 15px;
  border-radius:10px;
  background-color: #F9F9F9;
  color: #fff;
  border: none;
  font-weight: 500;
  font-family: '"Helvetica Neue", Helvetica, Arial, sans-serif';
  cursor: pointer;
  width: 250px;
  transition: transform 0.2s ease;
}

.btn-step img {
  height: 3rem;
}


.btn-step-1 img {
  height: 3rem;
}

.course-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(241, 241, 241, 0.692) !important;
}

.course-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 10px;
}

.course-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  flex: 1;
}

.remove-button {
  font-size: 20px;
  margin-left: auto;
  margin-right: 10px !important;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.remove-button:hover {
  color: #e74c3c;
}

.right-section {
  width: 40%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  

}

.summary {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
}

.course-details {
  margin-top: 20px;
  flex: 1;
  overflow-y: auto;
}

.course-details li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .inner-container {
    flex-direction: column;
  }
  .left-section {
    width: 100%;
  }
  .right-section {
    width: 100%;
    padding: 30px;
  }
}

.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  cursor: pointer;
  font-size: 18px;
  color: #333; /* You can customize the color */
  transition: color 0.3s ease;
}

.icon:hover {
  color: #007bff; /* Change color on hover */
}

.quantity {
  font-size: 16px;
  font-weight: bold;
}

