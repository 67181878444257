.about-item{
    height: 22rem;
}

.do_alone_section{
    height: 400px ; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(248, 248, 248);
  }

  .become_instructor{
    height: 300px ; 
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff
  }

  
  .become_instructor p{
    text-align: center;
    font-size: larger;
    font-weight: bolder;
    max-width: 500px;
  }

  
  .become_instructor h1{
    font-weight: bolder;
    margin-top: 3rem;
  }

  
.do_alone_section p{
        text-align: center;
        max-width: 500px;
  }

.do_alone_section h1{
    color: #309255;
}  

  .do_alone_section span{
    font-weight: bolder;
    font-size: 1rem;
}

.ud-btn-large {
    background-color: #1c1d1f;
}

.ud-btn-large{
    color: #fff;
}

.ud-btn-large {
    height: 4.8rem;
    border: none;
    cursor: pointer;
    padding: 0 1.2rem;
    white-space: nowrap;
    width: 20rem;
}

