.site-wrap {
    max-width: 850px;
    margin: 1px auto;
    padding: 40px;
    text-align: justify;
    overflow: hidden;
    text-align: center;
    padding-top: 15rem;
}

.banner-image{
    background-image: url(https://upgrade-skills.com/pix/aubg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 100%;
    height: 200px;
}

.card-img-top {
    text-align: center;
}



h2 {
    color: #01766e;
    text-align: center;
}

h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #737373;

}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #737373;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
}