.animated-tab-container {
  /* max-width: 700px; */
  width: 100%;
  background-color: #e7f8ee;
  border-radius: 10px;
  padding: 15px;
}

.animated-tab-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  padding: 10px;
}

.animated-tab {
  flex: 1;
  max-width: 130px;
  height: 45px;
  line-height: 41px;
  text-align: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  background-color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.animated-tab.active {
  background-color: #30a655;
  color: #fff;
  cursor: pointer;
}

.tab-content {
  height: calc(100% - 60px);
  padding: 10px;
}

.tab-pane {
  height: 100%;
  display: none;
}

.tab-pane.active {
  display: block;
  /* cursor: pointer; */
}

@media only screen and (max-width: 600px) {
  .animated-tab {
    margin-left: 5px;
  }
}