.thankyou-wrapper{
    width:100%;
    height:auto;
    margin:auto;
    background:#ffffff; 
    padding:10px 0px 50px;
  }
  .thankyou-wrapper h1{
    font:100px Arial, Helvetica, sans-serif;
    text-align:center;
    color:#333333;
    padding:0px 10px 10px;
  }
  .thankyou-wrapper p{
    font:26px Arial, Helvetica, sans-serif;
    text-align:center;
    color:#333333;
    padding:5px 10px 10px;
  }
  .thankyou-wrapper a{
    font:26px Arial, Helvetica, sans-serif;
    text-align:center;
    color:#ffffff;
    display:block;
    text-decoration:none;
    width:250px;
    background:#309255;
    margin:10px auto 0px;
    padding:15px 20px 15px;
    border-bottom:5px solid #ffffff;
  }
  .thankyou-wrapper a:hover{
    font:26px Arial, Helvetica, sans-serif;
    text-align:center;
    color:#ffffff;
    display:block;
    text-decoration:none;
    width:250px;
    background:#000;
    margin:10px auto 0px;
    padding:15px 20px 15px;
    border-bottom:5px solid #309255;
  }