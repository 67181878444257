.unique-tab-slider {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #eefbf3;
  padding: 30px;
  position: relative;
  margin-top: 80px;
}

.unique-tab-slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.unique-tab-slider-header {
  display: flex;
  align-items: center;
}

.unique-tab-slider-tabs-container {
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.unique-tab-slider-tab {
  padding: 10px 20px;
  border-radius: 25px;
  background-color: white;
  color: black;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.unique-tab-slider-tab.active {
  background-color: #008080;
  color: white;
}

.unique-tab-slider-arrows {
  width: 40px;
  height: 40px;
  background-color: white;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.unique-tab-slider-arrows.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.unique-tab-slider-arrow-left {
  left: 10px;
}

.unique-tab-slider-arrow-right {
  right: 10px;
}

@media only screen and (max-width: 768px) {
  .unique-tab-slider {
    max-width: 100%;
    padding: 5px;
  }

  .unique-tab-slider-tab {
    padding: 5px 10px;
    font-size: 14px;
  }

  .unique-tab-slider-arrows {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .unique-tab-slider-tab {
    padding: 5px;
    font-size: 12px;
  }

  .unique-tab-slider-arrows {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}
