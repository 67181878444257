.error-display{
    background-color: red;
    text-align: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    color: white;
}

.error-display p{
    color: white;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}