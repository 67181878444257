/* .bg-mainheaderbackgournd {
    background-color: #eefbf3 !important;
} */
.mt-1 {
    margin-top: 10px;
}
.mr-1 {
    margin-right: 10px !important;
}
.mt-0 {
    padding-top: 0 !important;
}
.mt-10 {
    margin-top: 130px !important;
}
.instructor-btn-success-width{
    color: #309255;
    border: 1px solid #309255;
    background-color: #ffffff;
    width: 50%;
    text-align: center;
    border-radius:10px;
    font-size: 1rem;
    padding: 8px 15px;
    font-weight: 400;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.instructor-btn-success-width:hover {
    background-color: #309255;
    color: #ffffff;
}

.instructor-btn-success {
    color: #309255;
    border: 1px solid #309255;
    background-color: #ffffff;
    border-radius:10px;
    font-size: 1rem;
    padding: 8px 15px;
    font-weight: 400;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.instructor-btn-success:hover {
    background-color: #309255;
    color: #ffffff;
}

.instructor-btn-error {
    color: #F5A375;
    border: 1px solid #F5A375;
    background-color: #ffffff;
    border-radius:10px;
    font-size: 1rem;
    padding: 8px 15px;
    font-weight: 400;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.instructor-btn-error:hover {
    background-color: #F5A375;
    color: #ffffff;
}
.h-100percent{
    height: 90% !important;
    background-color: #309255;
}
.custom-transition {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.item-center {
    display: flex;
    justify-content: center;
}

.file-upload-wrapper {
    position: relative;
    margin: 6px;
    width: 100%;
  }
  
  .file-upload-input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0.5rem;
    border-radius: 10px;
    border: 1px solid rgba(48, 146, 85, 0.2);
    font-weight: 600;
    color: #797979;
    background-color: #fff;
    cursor: pointer;
    /* text-align: center; */
  }
  
  .file-upload-label:focus,
  .file-upload-label:active {
    border: 1px solid seagreen;
  }
  
  .file-upload-placeholder {
    flex: 1;
  }
  
  /* Optional: Add a hover effect */
  .file-upload-label:hover {
    border: 1px solid seagreen;
    color: seagreen;
  }
  