/* .main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #fff;
    padding: 0 10px;
} */
.wrapper{
    position: relative;
    max-width: 1000px;
    padding: 15px;
    background: #eefbf3;
    padding: 50px 20px;
    border-radius: 10px;
    margin-top: 10px;
    overflow-x: hidden;
    transition: .3s ease;
    display: flex;
    align-items: center;
    gap: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .12);
}
.wrapper .icon{
    position: relative;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .12);
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon i{
    position: relative;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 55px;
    border-radius: 100%;
    font-size: 1.5em;
    cursor: pointer;
}
.icon i:hover{
    background: #efedfb;
}
.wrapper .tabsBox{
    display: flex;
    gap: 15px;
    scroll-behavior: smooth;
    overflow-x: hidden;
    list-style: none;
}
.tabsBox.dragging{
    cursor: grab;
    scroll-behavior: auto;
}
.tabsBox .tab{
    background: white;
    font-size: 1.18rem;
    padding: 10px 25px;
    border-radius: 30px;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid transparent; /* Add a transparent border */
    transition: 0.5s all;
}
.tabsBox .tab:hover{
    border: 1px solid gray;
    transition: 0.5s all;
    color: #19b053;
}
.tabsBox.dragging .tab{
    user-select: none;
    pointer-events: none;
    transition: .3s ease;
}
.tabsBox .tab.active{
    color: #fff;
    background: #19b053;
    transition: .3s ease;
}
/* .toggle{
    position: fixed;
    top: 50px;
    right: 50px;
    width: 55px;
    height: 55px;
    background: #00b8ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .12);
    transition: .3s ease;
    cursor: pointer;
} */
.toggle i{
    font-size: 1.8em;
    color: #fff;
}
.toggle.active{
    background: #fff;
}
.toggle i:last-child{
    position: absolute;
    visibility: hidden;
}
.toggle.active i:last-child{
    visibility: visible;
    color: #0f0f0f;
}
.toggle.active i:first-child{
    visibility: hidden;
}
.dark{
    position: absolute;
    top: 52.5px;
    right: 52.5px;
    width: 50px;
    height: 50px;
    background: #0f0f0f;
    border-radius: 50%;
    transition: .5s ease;
    z-index: -5;
}
.dark.active{
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    transition: .5s ease;
}   
.dark.active ~ .wrapper{
    background: #fff;
    transition: .3s ease;
}

@media only screen and (max-width:768px){
    .wrapper{
        width: 350px;
        padding: 5px 5px;
    }
}   